.AboutMeTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 100px;
    padding-right: 100px;
}

.grid{
    padding: 30px;
    display: flex;
    justify-content: center;
}

.gridItem{
    text-align: center;
}

.photo{
    text-align: center;
    border-radius: 10%;
}

.divider{
    padding: 10px 
}