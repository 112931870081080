body{
    background: rgb(0,2,36);
  background: linear-gradient(90deg, rgba(0,2,36,1) 0%, rgba(9,120,121,1) 51%, rgba(0,18,61,1) 100%);
  width: 100vw
  }
  
  .topBar{
    background-color:transparent;
  }
  
  .title{
    align-content: center;
    height: 94vh;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    flex-direction: column;
  }
  
  .image{
    height: 90vh;
    padding-left: 10px;
    overflow: hidden;
    border-radius: 51% 49% 70% 30% / 30% 32% 68% 70% ;
    
  }
  
 .titleScreen{
  margin-bottom: 50px;
 }
  
  .anim-typewriter{
    animation: typewriter 2s steps(24) 1s 1 normal both,
    blinkTextCursor 500ms steps(30) infinite 
  }
  @keyframes typewriter{
    from{width: 0;}
    to{width:7.2em}
  }
  @keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }
  
  
  