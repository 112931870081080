.titles{
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-right: 100px;
}

.summary{
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 150px;
}

.keyFeatures{
    display: flex;
    justify-content: space-between;
    padding: 30px;
}