.ProjectTitle {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-right: 100px;
}

.projectsCard {
    display: flex;
    justify-content: center;
    min-width: 275px;
    min-height: 400px;
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);

}

.projectsBox{
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
}
