.strengthsHeading {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
}

.cardBox {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    margin-top: 100px;
    margin-bottom: 100px
}

.infoCard {
    width: 300px;
    min-height: 350px;
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);

}

.aboutMeButton {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
}

.projectSubHeader{
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
}

.expertiseDiv{
    display:block;
    overflow: auto;
    height: 100vh;
    width: 100vw;
}

.philosophyDiv{
    display:block;
    overflow: auto;
    height: 100vh;
    width: 100vw;
}